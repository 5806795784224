<template>
  <div>
    <div class="header-top">
      <img class="tp-img" src="../../assets/images/relation/cn-ban.png" />
      <div class="tp-title">联系我们</div>
      <div class="tp-ic"></div>
      <div class="tp-body"><div class="underline"></div></div>

        <div style="display: flex;margin: auto;width: 1120px;height: 300px;">

          <div class="tp-content">
            <div class="content-title">苏州腾康环保科技有限公司</div>
            <div class="content">电话: 0512- -68709231/15366204535</div>
            <div class="content">公司邮箱: tengkanghb@aliyun.com</div>
            <div class="content">网址: http://tengkang-cn.com</div>
            <div class="content">
              设备基地:扬州市宝应县小官庄镇工业集中区范石路2号
            </div>
            <div class="content">办公地址:苏州市吴中区东方大道988号吴中大厦</div>
            <div style="margin-bottom: 20px"></div>
          </div>
          <div id="container"></div>
          <div id="equipmentmap"></div>
        </div>

      <div style="height: 20px"></div>

    </div>
  </div>
</template>
<script>
import AMapLoader from '@amap/amap-jsapi-loader';
export default {
  data() {
    return {
      map:null,
      marker:null,
      facilitymap:null,
      facilitymarker:null,
    };
  },
  mounted () {
    this.initMap()
    this.equipmentmap()
  },
  methods:{
    initMap(){
      AMapLoader.load({
        key:"e82cb75cf3d9c34edc1d2eed79a66145",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:['AMap.Marker','AMap.LngLat'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.map = new AMap.Map("container",{  //设置地图容器id
          viewMode:"3D",    //是否为3D地图模式
          zoom:18,           //初始化地图级别
          center:[120.675605,31.26568], //初始化地图中心点位置
        });
      }).catch(e=>{
        console.log(e);
      })
      setTimeout(()=>{
        this.addmarker()
      },500)

    },
    equipmentmap(){
      AMapLoader.load({
        key:"e82cb75cf3d9c34edc1d2eed79a66145",             // 申请好的Web端开发者Key，首次调用 load 时必填
        version:"2.0",      // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins:['AMap.Marker','AMap.LngLat'],       // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap)=>{
        this.facilitymap = new AMap.Map("equipmentmap",{  //设置地图容器id
          viewMode:"3D",    //是否为3D地图模式
          zoom:11,           //初始化地图级别
          center:[119.453711,33.180466], //初始化地图中心点位置
        });
      }).catch(e=>{
        console.log(e);
      })
      setTimeout(()=>{
        this.mapaddmarker()
      },500)

    },
    addmarker(){
      // 创建一个 Marker 实例：
      this.marker = new AMap.Marker({
        position: new AMap.LngLat(120.675605,31.26568),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '办公地址'
      });

// 将创建的点标记添加到已有的地图实例：
      this.map.add(this.marker);
    var p1 = [115.5133672140,29.8607079801];
var p2 = [115.5329659003,29.8579903986,];
// 返回 p1 到 p2 间的地面距离，单位：米
var dis = AMap.GeometryUtil.distance(p1, p2);
// 返回线段 p0-p1-p2 的实际长度，单位：米
     
      console.log(dis)
    },

    mapaddmarker(){
      // 创建一个 Marker 实例：
      this.facilitymarker = new AMap.Marker({
        position: new AMap.LngLat(119.453711,33.180466),   // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
        title: '设备基地'
      });

// 将创建的点标记添加到已有的地图实例：
      this.facilitymap.add(this.facilitymarker);
    }
  }
};
</script>
<style lang="scss" scoped>
#container{
  //padding:0px;
  margin-left: 10px;
  width: 600px;
  height: 300px;
  border: #76c817 1px solid;


}
#equipmentmap{
  //padding:0px;
  margin-left: 10px;
  width: 600px;
  height: 300px;
  border: #76c817 1px solid;

}
.header-top {
  min-width: 1250px;
  height: 100%;
  margin: auto;
  .tp-title {
    text-align: center;
    margin-top: 35px;
    color: #1f1f1f;
    font-size: 31px;
  }
  .tp-ic {
    width: 40px;
    height: 4px;
    margin: 20px auto;
    background:  #8cc6ee;
  }
  .tp-content {

    width: 645px;
    height: 300px;
    margin:auto;
    z-index: 10;
    background-color:#8cc6ee;
    border: #76c817 1px solid;
    padding-left: 15px;
    padding-right: 15px;
    //height: 300px;
  }
}
.underline {
  width: 100%;
  height: 18px;
  margin: 20px auto;
  background-repeat: no-repeat;
  background-image: url("../../assets/images/zigongsi/i-title.png");
}
.tp-body {
  width: 560px;
  margin: auto;
}
.tp-img {
  height: 380px;
  width: 100%;
}
.tp-body-cn {
  margin: auto;
  width: 560px;
  text-align: center;
  color: #333333;
  font-size: 16px;
  font-weight: 600;
}
.recruit-title {
  color: #666666;
  line-height: 30px;
}
.recruit-content {
  color: #444444;
  font-size: 15px;
  line-height: 30px;
}
.content {
  font-size: 15px;
  line-height: 30px;
  color: #444;
}
.content-title {
  margin-top: 35px;
  margin-bottom: 15px;
  margin-left: 20px;

  color: #000;
  line-height: 30px;
  font-size: 25px;
}
</style>
